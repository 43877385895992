import React from 'react'
import st from '../ServicesPage/servicepage.module.scss'

const About: React.FC = () => {
  return (
    <section>
      <div className={'wrapper'}>
        <div className={st.about__container}>
          <p>
            At <b>L.A. Dance Floor Inc.</b>, we specialize in providing high-quality, seamless dance floors for events throughout Los Angeles and Orange County. Whether you're hosting a wedding, corporate event, or private party, our customizable dance floors are designed to enhance any occasion.
          </p>
          <h4 className={st.about__subtitle}>Our Offerings:</h4>
          <p className={st.about__text}><i className="fa-solid fa-circle-check"></i> Custom Colors & Finishes: Choose from a variety of vibrant colors and finishes, including matte and gloss options, to perfectly match your event's theme. </p>
          <p className={st.about__text}><i className="fa-solid fa-circle-check"></i> Seamless, Super Gloss Laminate: Our dance floors feature German-mode laminate that is smooth, durable, and non-slippery, ensuring safety while dancing.</p>
          <p className={st.about__text}><i className="fa-solid fa-circle-check"></i> Perfect Fit: Each dance floor panel measures 3 ft by 4 ft, allowing for easy customization to fit any space.
          Monogram & Decor Customization:</p>
          <p className={st.about__othtext}>We also offer custom monograms and decorative elements to personalize your event. Whether you need a simple, elegant look or a vibrant, eye-catching design, L.A. Dance Floor Inc. can make your vision a reality. Serving the L.A. and Orange County areas, we deliver and set up high-quality dance floors for any event size.</p>
        </div>
      </div>
    </section>
  )
}

export default About
