export type Login = {
   email: string,
   password: string
}

export type ContactForm = {
   name: string,
   email: string,
   subject: string,
   textarea: string
}

// export enum LINKS {
//    CONTACT = 'http://localhost/MyProjects/ladancefloor.com/backend/mail.php',
//    LOGIN = 'http://localhost/MyProjects/ladancefloor.com/backend/login.php',
//    ADDPICTURES = 'http://localhost/MyProjects/ladancefloor.comladancefloor.com/backend/addpictures.php',
//    GETPICTURE = 'http://localhost/MyProjects/ladancefloor.com/backend/getpicture.php',
//    DELETEPICTURE = 'http://localhost/MyProjects/ladancefloor.com/backend/deletepicture.php'
// }

export enum LINKS {
   CONTACT = 'https://ladancefloor.com/backend/mail.php',
   LOGIN = 'https://ladancefloor.com/backend/login.php',
   ADDPICTURES = 'https://ladancefloor.com/backend/addpictures.php',
   GETPICTURE = 'https://ladancefloor.com/backend/getpicture.php',
   DELETEPICTURE = 'https://ladancefloor.com/backend/deletepicture.php'
}

export type IHeaders = {
   [key: string]: string
}

export type ContactSliceState = {
   contactMessage?: Object,
   allMessages: ContactForm[]
}

export type LoginSliceState = {
   loginAdmin: Object,
   response: boolean,
   getSession: string,
   uploadDone?: any,
   allPics: string[],
   responsePicDownload: boolean
}


export interface BoxImageType {
   image: string,
   index: number,
   id?: number,
   cut?: boolean
}

export interface ServicesType {
   image: string,
   title: string,
   text: string
}

