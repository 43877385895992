import React from 'react';
import st from './footer.module.scss';
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
   return (
      <footer className={st.footer}>
         <div className='wrapper'>
            <div className={st.box__cont}>
               <div>
                  <h3 className={st.box__title}>About Us</h3>
                  <p>At <b>L.A. Dance Floor Inc.</b>, we specialize in providing high-quality, seamless dance floors for events throughout Los Angeles and Orange County</p>
                  <ul className={st.icon__menu}>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                     </li>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-instagram"></i>
                        </Link>
                     </li>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-youtube"></i>
                        </Link>
                     </li>
                  </ul>
               </div>
               <div className={st.second__box}>
                  <h3 className={st.box__title}>Contact Info</h3>
                  <p>
                     <span><i className="fa-solid fa-location-dot"></i>  Address: </span> <Link to='https://www.google.com/maps?ll=34.168135,-118.293459&z=17&t=m&hl=en&gl=RU&mapclient=embed&q=1706+Standard+Ave+Glendale,+CA+91201+USA' target='_blank'>1706 Standard Ave Glendale CA 91201</Link> </p>
                  <p className={st.text__marg}>
                     <span><i className="fa-solid fa-globe"></i> Website: </span> <Link to={'/'}>ladancefloor.com</Link> </p>
                  <p className={st.text__marg}>
                     <span><i className="fa-solid fa-envelope"></i> Email: </span> <Link to='mailto:ladancefloorinc@gmail.com'>ladancefloorinc@gmail.com</Link></p>
                  <p className={st.text__marg}><span><i className="fa-solid fa-phone"></i> Phone: </span> <Link to='tel:+18185101302'>818.510.1302</Link></p>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
