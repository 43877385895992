import React, { useEffect } from 'react'
import st from './gallery.module.scss'
import cn from 'classnames'
import BoxImage from '../../components/BoxImage'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getPictures } from '../../features/Login/LoginAPI'

const Gallery: React.FC = () => {
  const { allPics, responsePicDownload } = useAppSelector((state: any) => state.login);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPictures());
  }, [])

  return (
    <section className={st.section}>
      <div className={cn('wrapper', st.container)}>
        {!responsePicDownload ? <h3>Loading...</h3> :
          allPics && allPics.map((elem: any, index: number) => (
            <BoxImage
              key={elem.id}
              image={elem.pic_name}
              index={index}
              id={elem.id}
            />
          ))}
      </div>
    </section>
  )
}

export default Gallery
